import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn, isAdminLoggedIn, isTokenExpired } from "@/auth/utils";
import { Requests } from "@/api/requests.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: "/",
      name: "root",
      redirect: "/login",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
        resource: "Auth",
      },
    },
    {
      path: "/register",
      name: "register",
      component: () => import("@/views/Register.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/passwordlostverify",
      name: "password_verify",
      component: () => import("@/views/PasswordRecoveryVerify.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/verify_email",
      name: "verify_email",
      component: () => import("@/views/VerifyEmail.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/profile",
      name: "user_profile",
      component: () => import("@/views/UserProfile.vue"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
    },
    {
      path: "/saved_projects",
      name: "saved_projects",
      component: () => import("@/views/SavedProjects.vue"),
    },
    {
      path: "/clients",
      name: "clients",
      component: () => import("@/views/Clients.vue"),
    },
    {
      path: "/manual",
      name: "manual",
      beforeEnter() {window.open('https://api.smart378.it/attachment/Manuale_Operativo_SMART_378.pdf', '_blank')}
    },
    {
      path: "/new_project",
      name: "new_project",
      component: () => import("@/views/NewProject.vue"),
    },
    {
      path: "/new_project_maintenance",
      name: "new_project_maintenance",
      component: () => import("@/views/NewProjectMaintenance.vue"),
    },

    {
      path: "/new_project_type",
      name: "new_project_type",
      component: () => import("@/views/NewProjectType.vue"),
    },

    {
      path: "/edit_project/:id",
      name: "edit_project",
      component: () => import("@/views/EditProject.vue"),
    },
    {
      path: "/edit_project_maintenance/:id",
      name: "edit_project",
      component: () => import("@/views/EditProjectMaintenance.vue"),
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () => import("@/views/Pricing.vue"),
    },
    {
      path: "/cart",
      name: "cart",
      component: () => import("@/views/Cart.vue"),
    },
    {
      path: "/order-error",
      name: "order_error",
      component: () => import("@/views/OrderError.vue"),
    },
    {
      path: "/order-success",
      name: "order_success",
      component: () => import("@/views/OrderSuccess.vue"),
    },
    {
      path: "*",
      redirect: "error-404",
    },

    //Backend

    {
      path: "/backend",
      name: "login_backend",
      component: () => import("@/views/backend/Login.vue"),
      meta: {
        backend: true,
        resource: "Auth",
        layout: "full",
      },
    },
    {
      path: "/backend/dashboard",
      name: "dashboard_backend",
      component: () => import("@/views/backend/Dashboard.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/account",
      name: "list_account_backend",
      component: () => import("@/views/backend/ListAccount.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/account/:id",
      name: "backend_account_details",
      component: () => import("@/views/backend/AccountDetails.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/account/:id/projects",
      name: "backend_account_projects",
      component: () => import("@/views/backend/AccountProjects.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/account/:id/projects/:project_id",
      name: "backend_account_project_details",
      component: () => import("@/views/backend/AccountProjectDetails.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/licences",
      name: "backend_licences",
      component: () => import("@/views/backend/GestioneLicenze.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/block_settings",
      name: "backend_block_settings",
      component: () => import("@/views/backend/BlockSettings.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/backend_agents",
      name: "backend_agents",
      component: () => import("@/views/backend/Agents.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/backend_agent_details/:id_agent?",
      name: "backend_agent",
      component: () => import("@/views/backend/Agent.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/backend_payments",
      name: "backend_payments",
      component: () => import("@/views/backend/Payments.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/backend_lost_payments",
      name: "backend_lost_payments",
      component: () => import("@/views/backend/LostPayments.vue"),
      meta: {
        backend: true,
      },
    },
    {
      path: "/backend/backend_agent_customers",
      name: "backend_agent_customers",
      component: () => import("@/views/backend/AgentCustomers.vue"),
      meta: {
        backend: true,
      },
    },
  ],
});

router.beforeEach(async (to, _, next) => {
  if (!to.meta.backend) {
    const isLoggedIn = isUserLoggedIn();
    const isTokenExpired = await Requests.getUser();

    if (to.meta.resource != "Auth") {
      localStorage.setItem("user", JSON.stringify(isTokenExpired.me.payload));
    }

    if (!canNavigate(to)) {
      if (isLoggedIn && isTokenExpired.me.success == "false") {
        localStorage.removeItem("user");
        localStorage.removeItem("access_token");
        localStorage.removeItem("remember_me");
        return next({ name: "login" });
      }
      if (!isLoggedIn) return next({ name: "login" });
    }

    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      next({ name: "dashboard" });
    }
  } else {
    const isLoggedIn = isAdminLoggedIn();

    if (!canNavigate(to)) {
      if (!isLoggedIn) return next({ name: "login_backend" });
    }
  }
  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
