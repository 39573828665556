import Vue from 'vue'
import { API_BASE_URL } from '@/config';

// axios
import axios from 'axios'

const axiosIns = axios.create({
    baseURL: API_BASE_URL,
    timeout: 120000,
})

Vue.prototype.$http = axiosIns

export default axiosIns